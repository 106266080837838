<template>
  <q-layout view="hHh lpR fFf">
    <q-header
      reveal
      elevated
      class="text-primary bg-white"
    >
      <q-toolbar>
        <q-avatar
          square
          class="q-mr-xs cursor-pointer"
          @click="openWebsite('/')"
        >
          <img src="/images/picto.png" />
        </q-avatar>

        <q-btn
          flat
          @click="navigateTo('/')"
        >
          <q-toolbar-title>
            Streamers
          </q-toolbar-title>
        </q-btn>

        <q-space />

        <q-btn
          flat
          label="Boutique"
          icon-right="open_in_new"
          @click="openWebsite('/boutique')"
        />
      </q-toolbar>
    </q-header>

    <q-page-container>
      <slot />
    </q-page-container>
  </q-layout>
</template>

<script setup lang="ts">
const baseUrl = 'https://www.play4live.ch'

function openWebsite (slug: string) {
  const url = baseUrl + slug

  navigateTo(url, {
    external: true,
    open: {
      target: '_blank'
    }
  })
}
</script>
